/***importing components***/
import home from "./components/home.vue";
import contact from "./components/contact.vue";




/***importing packages***/
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router"; //router package
import App from "./App.vue";







/***creating routes***/
const routes = [
    { path: "/", component: home },
    { path: "/home", component: home },
    { path: "/contact", component: contact },
];

/***configuring router***/
const router = createRouter({
    history: createWebHistory(),
    routes,
});


/***create app instance***/
const app = createApp(App);

/***router instance & app mounting ***/

app.use(router);



app.mount("#app");