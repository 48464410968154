<template>
  <div>
    <mainHeader class="main-header"></mainHeader>
    <div class="main-container">
      <sphere></sphere>
      <router-view v-slot="{ Component }">
        <transition name="slide" >
          <component :is="Component" :key="$route.path"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import mainHeader from "./components/mainHeader.vue";
import sphere from "./components/sphere.vue";

export default {
  name: "App",
  components: {
    mainHeader,
    sphere,
  },
};
</script>

<style>
.slide-enter-active{
  transition: opacity 1s, transform 1s;
}

.slide-leave-active{
  transition: opacity 1s, transform 1s;
}


.slide-enter-from,
.slide-leave-to{
  opacity: 0;
  transform: translateX(-80%);
}




* {
  padding: 0;
  font-family: "Prompt";
  box-sizing: border-box;
  margin: 0;
}

#app {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      132.55deg,
      rgba(255, 255, 255, 0.3) 11.57%,
      rgba(255, 255, 255, 0.05) 72.62%
    ),
    url(./assets/Background-Color-Effect.jpeg);
  background-size: cover;
  background-position: center;
  padding: 0 5%;
  position: fixed !important;
}

.main-container {
  display: flex;
  margin-top: 5.2%;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}

/* /////////////// /////////////// */

@media screen and (min-width: 1640px) {
  .main-container {
    margin-top: 4.5%;
  }
}
</style>
