<template>
  <div class="content">
    <h1 class="main-h1">Digital outsourcers focused in digital growth.</h1>

    <h2 class="main-h2">
      We help organizations by
      <br />maximizing their opportunities
      <br />in the
      digital world.
    </h2>

    <div class="buttons">
      <router-link to="/contact">
        <button class="button-1">CONTACT</button>
      </router-link>
      <button class="button-2">CALL US</button>
    </div>
  </div>
</template>

<script>

</script>


<style scoped>
.content {
  margin-top: 1%;
  color: #edf2f4;
}

.content h1 {
  font-family: "Prompt";
  font-weight: 500;
  font-size: 2.63rem;
  line-height: 54px;
  font-size: 46px;
  text-align: left;
  margin-bottom: 1.5%;
  /* border: 1px solid red; */
  width: 63%;
}

.content h2 {
  font-family: "Prompt";
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 35px;

  text-align: left;
  letter-spacing: 0.01px;
  mix-blend-mode: overlay;
  width: 50%;
  /* border: 1px solid green; */
}

.buttons {
  display: flex;
  width: 45%;
  margin-top: 2.76%;
}

.button-1 {
  background-color: #202020;
  border-radius: 5px;
  color: #edf2f4;
  cursor: pointer;
  width: 150px;
  height: 42px;
  font-weight: 300;
  font-family: "Helvetica Neue";
  font-size: 14px;
  border: 0.5px solid #202020;
  margin-right: 1rem;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.8px;
}

.button-2 {
  background-color: transparent;
  border: 0.5px solid #edf2f4;
  border-radius: 5px;
  color: #edf2f4;
  cursor: pointer;
  width: 150px;
  height: 42px;
  font-weight: 300;
  font-family: "Helvetica Neue";
  font-size: 14px;

  margin-right: 1rem;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.8px;
  z-index: 1;
}

@media screen and (min-width: 1400px) {
  .content {
    /* border: 1px solid seagreen; */
  }

  .content h1 {
    font-size: 3.25rem;
    width: 66%;
    line-height: 4.42rem;
  }

  .content .main-h2 {
    /* border: 1px solid salmon; */
    font-size: 2rem;
    width: 55%;
  }

  .buttons {
    margin-top: 3.5%;
  }

  .button-1 {
    width: 10.42rem;
    height: 3.25rem;
    font-weight: 400;
    font-size: 0.92rem;
  }

  .button-2 {
    width: 10.42rem;
    font-weight: 400;
    height: 3.25rem;
    font-size: 0.92rem;
  }
}

@media screen and (min-width: 1640px) {
  .content {
  
    margin-left: 1.5%;
  }
  .content h1 {
    font-size: 3.75rem;
    line-height: 5rem;
    margin-bottom: 2.5%;
    width: 66%;
  }

  .button-1 {
    margin-right: 1.4rem;
  }
}
</style>
