<template>
  <div class="mainHeader">
    <div class="nav-logo">
      <router-link to="/">
        <img src="../assets/logo-large.svg" alt="logo" />
      </router-link>
    </div>

    <nav id="menu">
      <ul>
        <li>
          <router-link class="nav-element" to="/contact" v-bind:class="{ 'nav-unselected': $route.path == '/contact' }">
            <p>Capabilities</p>
            <svg
              width="71"
              height="2"
              viewBox="0 0 71 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="70.06"
                y1="1.25"
                x2="-5.81792e-08"
                y2="1.24999"
                stroke="#EDF2F4"
                stroke-width="1.5"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link class="nav-element" to="/contact" v-bind:class="{ 'nav-selected': $route.path == '/contact' }">
            <p>Contact</p>
            <svg
              width="71"
              height="2"
              viewBox="0 0 71 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="70.06"
                y1="1.25"
                x2="-5.81792e-08"
                y2="1.24999"
                stroke="#EDF2F4"
                stroke-width="1.5"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link to="/contact" class="nav-element" v-bind:class="{ 'nav-unselected': $route.path == '/contact' }">
            <p>About</p>
            <svg
              width="71"
              height="2"
              viewBox="0 0 71 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="70.06"
                y1="1.25"
                x2="-5.81792e-08"
                y2="1.24999"
                stroke="#EDF2F4"
                stroke-width="1.5"
              />
            </svg>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "mainHeader",
};
</script>

<style >


.mainHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

.nav-logo {
  z-index: 3;
}

.nav-logo img {
  width: 110px;
  cursor: pointer;
  /* border: 1px solid orange; */
}

nav ul {
  /* border: 1px solid blue; */
  margin-top: 0.05rem;
  list-style: none;
  text-align: right;
  color: #edf2f4;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  letter-spacing: 0.15px;
  font-style: normal;
  position: absolute;
  right: 0;
  margin-right: 2%;
  z-index: 3;
}

svg {
  stroke-dashoffset: 30.029998779296875;
  stroke-dasharray: 70.05999755859375;
  transition: stroke-dashoffset 0.6s ease;
  /* animation: selected 1s ease;
  animation-fill-mode: forwards; */
  width: 4.2rem;
}

.nav-element {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #edf2f4;
  text-decoration: none;
}

li:hover svg {
  stroke-dashoffset: 0;
  transition-timing-function: ease;
  transition-duration: 0.6s;
  
}

li p {
  /* margin-right: -0.625rem; */
  animation: nav-out 0.6s ease forwards;
  -o-animation: nav-out 0.6s ease forwards;
  -ms-animation: nav-out 0.6s ease forwards;
  -moz-animation: nav-out 0.6s ease forwards;
  -webkit-animation: nav-out 0.6s ease forwards;
}

li:hover p {
  animation: nav-in 0.6s ease forwards;
  -o-animation: nav-in 0.6s ease forwards;
  -ms-animation: nav-in .6s ease forwards;
  -moz-animation: nav-in 0.6s ease forwards;
  -webkit-animation: nav-in 0.6s ease forwards;
}

@keyframes nav-in {
  0% {
    margin-right: -0.625rem;
  }
  100% {
    margin-right: .9375rem;
  }
}


@keyframes nav-out {
  0% {
    margin-right: .9375rem;
  }
  100% {
    margin-right: -0.625rem;
  }
}

/* svg:hover {
  stroke-dashoffset: 0;
  transition-timing-function: ease;
  transition-duration: 1s;
} */

/* @keyframes selected {
  to {
    stroke-dashoffset: 0;
  }
} */


nav ul li {
  cursor: pointer;
  margin-bottom: 0.4rem;
}



.nav-selected p {
  animation: nav-in 0s ease forwards;
}

.nav-selected svg{
  stroke-dasharray: 0;
}

.nav-unselected {
  color:rgba(237, 242, 244, 0.5);
}

.nav-unselected:hover {
  color: #edf2f4;
} 

.nav-unselected svg {
  opacity: 0.5;
}

.nav-unselected:hover svg {
  opacity: 1;
}

@media screen and (min-width: 1640px) {
  .mainHeader {
    padding: 46px 0;
    padding-bottom: 20px;
    padding-left: 26px;
  }

  .nav-logo img {
    width: 150px;
  }
}
</style>

