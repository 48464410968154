<template>
  <canvas
    id="bg"
    v-bind:class="{ 'blur': $route.path == '/contact', 'unblur': $route.path != '/contact' }"
  ></canvas>
</template>

<script>
import * as THREE from "three";

export default {
  name: "sphere",
  data: function () {
    return {
      camera: null,
      renderer: null,
      scene: null,
      mesh: null,
      texture: null,
      geometry: null,
      material: null,
    };
  },
  methods: {
    init: () => {
      //Scene
      const scene = new THREE.Scene();

      ////////////////////////////////////////
      //Camera
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );

      ////////////////////////////////////////
      //MAIN-RENDERER
      const renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector("#bg"),
        clearAlpha: 1,
        alpha: true, //
        antialias: true,
      });
      renderer.setPixelRatio(window.devicePixelRatio);

      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.position.setZ(30);

      renderer.render(scene, camera);

      ////////////////////////////////////////
      //Texture
      const texture = new THREE.CanvasTexture(generateTexture());
      texture.magFilter = THREE.NearestFilter;
      texture.wrapT = THREE.RepeatWrapping;
      texture.wrapS = THREE.RepeatWrapping;

      texture.repeat.set(1, 16);

      function generateTexture() {
        const canvas = document.createElement("canvas");
        canvas.width = 2;
        canvas.height = 2;

        const context = canvas.getContext("2d");
        context.fillStyle = "white";
        context.fillRect(0, 1, 4, -4);
        ////////////////////
        return canvas;
        ////////////////////
      }

      ////////////////////////////////////////
      //GEOMETRY::MATERIAL
      const geometry = new THREE.SphereGeometry(13.2, 50, 50);

      const material = new THREE.MeshPhongMaterial({
        side: THREE.DoubleSide,
        alphaMap: texture,
        alphaTest: 0.5,
        alpha: true,
      });

      //Mesh
      const sphere = new THREE.Mesh(geometry, material);
      sphere.castShadow = true;
      sphere.receiveShadow = true;

      scene.add(sphere);

      ////////////////////////////////////////
      //Light

      const ambientLight = new THREE.AmbientLight(0x1d1d20);

      const clearPointLight = new THREE.PointLight(0xa0adaf, 1.86, 24);
      clearPointLight.castShadow = true;
      clearPointLight.shadow.bias = -0.005; // reduces self-shadowing on double-sided objects
      clearPointLight.position.set(0, 4, 21);

      const greyPointLight = new THREE.PointLight(0x7e8386);
      greyPointLight.position.set(10, -10, 10);

      scene.add(ambientLight, greyPointLight, clearPointLight);

      ///////////////////////////////////////////
      //RENDERER
      renderer.setClearColor(0x000000, 0); /// Transparent background color

      //On window resize
      window.addEventListener("resize", function () {
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
      });

      // function onWindowResize() {

      //     camera.aspect = window.innerWidth / window.innerHeight;
      //     camera.updateProjectionMatrix();

      // }

      const animate = function () {
        requestAnimationFrame(animate);
        sphere.rotation.x += -0.00005;
        sphere.rotation.z += -0.001;

        renderer.render(scene, camera);
      };

      ////////////////////
      animate();
      //////////////////
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
canvas {
  filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.06));
  mix-blend-mode: overlay;
  /* border: 1px solid yellow; */
  position: absolute;

  margin-top: -6%;
  margin-left: 18.3%;
  width: 100%;
  height: 100%;
}

.blur {
  -webkit-animation: blur-in 800ms ease 225ms forwards;
  -moz-animation: blur-in 800ms ease 225ms forwards;
  -ms-animation: blur-in 800ms ease 225ms forwards;
  -o-animation: blur-in 800ms ease 225ms forwards;
  animation: blur-in 800ms ease 225ms forwards;
}

.unblur {
  -webkit-animation: blur-out 750ms ease  forwards;
  -moz-animation: blur-out 750ms ease  forwards;
  -ms-animation: blur-out 750ms ease  forwards;
  -o-animation: blur-out 750ms ease  forwards;
  animation: blur-out 750ms ease forwards;
  -webkit-filter: blur(9px);
  -moz-filter: blur(9px);
  -o-filter: blur(9px);
  -ms-filter: blur(9px);
  filter:blur(9px)
}

@keyframes blur-in {
  0% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
  }
  25% {
    -webkit-filter: blur(2.25px);
    -moz-filter: blur(2.25px);
    -o-filter: blur(2.25px);
    -ms-filter: blur(2.25px);
  }
  50% {
    -webkit-filter: blur(4.5px);
    -moz-filter: blur(4.5px);
    -o-filter: blur(4.5px);
    -ms-filter: blur(4.5px);
  }
  75% {
    -webkit-filter: blur(6.75px);
    -moz-filter: blur(6.75px);
    -o-filter: blur(6.75px);
    -ms-filter: blur(6.75px);
  }
  100% {
    -webkit-filter: blur(9px);
    -moz-filter: blur(9px);
    -o-filter: blur(9px);
    -ms-filter: blur(9px);
  }
}

@keyframes blur-out {
  0% {
    -webkit-filter: blur(9px);
    -moz-filter: blur(9px);
    -o-filter: blur(9px);
    -ms-filter: blur(9px);
  }
  25% {
    -webkit-filter: blur(6.75px);
    -moz-filter: blur(6.75px);
    -o-filter: blur(6.75px);
    -ms-filter: blur(6.75px);
  }
  50% {
    -webkit-filter: blur(4.5px);
    -moz-filter: blur(4.5px);
    -o-filter: blur(4.5px);
    -ms-filter: blur(4.5px);
  }
  75% {
    -webkit-filter: blur(2.25px);
    -moz-filter: blur(2.25px);
    -o-filter: blur(2.25px);
    -ms-filter: blur(2.25px);
  }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
  }
}

@media screen and (min-width: 1640px) {
  .blur-effect {
    filter: blur(1rem);
  }
}
</style>
