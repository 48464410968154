<template>
    <div class="sidebar wrapper">
        <div name="slide">
            <div class="sidebar-panel">
                <div class="sideblur-effect"></div>
                <div class="contact-form">
                    <div class="main-contact">
                        <div class="contact-head">
                            <h1>Connect with us.</h1>

                            <div @click="$router.back()" class="back-button">
                                <span class="backarrow-symbol">🠔</span>
                                <span class="back-button-text">Back</span>
                            </div>
                        </div>

                        <Form
                            class="main-form"
                            @submit="onSubmit"
                            :validation-schema="schema"
                            v-slot="{ isSubmitting, }"
                        >
                            <div class="name-fields">
                                <div class="name firstrow-margin">
                                    <h3>┃NAME</h3>
                                    <div class="boxes-padding">
                                        <p>First</p>

                                        <div class="input-box">
                                            <Field
                                                class="input"
                                                name="firstname"
                                                :validateOnBlur="false"
                                            />
                                        </div>

                                        <ErrorMessage class="error-message" name="firstname" />
                                    </div>
                                </div>

                                <div class="name">
                                    <div class="boxes-padding">
                                        <p>Last</p>

                                        <div class="input-box">
                                            <Field
                                                class="input"
                                                name="lastname"
                                                :validateOnBlur="false"
                                            />
                                        </div>
                                        <ErrorMessage class="error-message" name="lastname" />
                                    </div>
                                </div>
                            </div>

                            <div class="contact-fields">
                                <div class="name firstrow-margin">
                                    <h3>┃EMAIL</h3>
                                    <div class="boxes-padding">
                                        <p>Email Address</p>

                                        <div class="input-box">
                                            <Field
                                                class="input"
                                                name="email"
                                                :validateOnBlur="false"
                                            />
                                        </div>
                                        <ErrorMessage class="error-message" name="email" />
                                    </div>
                                </div>

                                <div class="name">
                                    <h3>┃PHONE</h3>
                                    <div class="boxes-padding">
                                        <p>Phone Number</p>

                                        <div class="input-box">
                                            <Field
                                                class="input"
                                                name="phoneNumb"
                                                :validateOnBlur="false"
                                                placeholder="+1 323 XXX XX XX"
                                            />
                                        </div>
                                        <ErrorMessage class="error-message" name="phoneNumb" />
                                    </div>
                                </div>
                            </div>

                            <div class="message-field">
                                <div class="name">
                                    <h3>┃HOW CAN WE HELP YOU</h3>
                                    <div class="textbox-padding">
                                        <p class="message-description">Details and Information</p>

                                        <div class="input-box text-area">
                                            <Field
                                                as="textarea"
                                                class="input message"
                                                name="message"
                                                placeholder="Tell us about your idea your project or idea."
                                                :validateOnBlur="false"
                                            ></Field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="textbox-padding">
                                <button 
                                    :disabled="isSubmitting"
                                    class="submit-button { 'submitting': isSubmitting }"
                                >SUBMIT</button>

                                <span class="error-message success-message" v-if="sended">Thanks for get it touch</span>
                                <span class="error-message suberror-message" v-if="notsended">Ouch! Something went wrong, please try again later</span>
                            
                                <ErrorMessage
                                    class="error-message messagebox-error"
                                    name="message"
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios'


export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        
    },
    data() {
        

        let sended = false;
        let notsended  = false;
                
        const invalidMessage = "Please type a valid "
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$//* TESTED REGEX FOR PHONE NUMBER FIELD VALIDATION */


        const schema = yup.object({
            firstname: yup.string().required('Your first name is required *').min(2, 'Please type your first name completely'),
            lastname: yup.string().required('Your last name is required *').min(2, 'Please type your last name completely'),
            email: yup.string().required('Your email is required *').min(6).email('Please type a valid email address'),
            phoneNumb: yup.string().required('Your phone number is required *').matches(phoneRegExp, invalidMessage + 'phone number').min(9, invalidMessage + 'phone number').max(13, invalidMessage + 'phone number'),
            message: yup.string().required('Please tell us about your project').min(10, 'Please tell us a little bit more about your project...'),
        });


        return {
            sended,
            notsended,
            schema,
        };

    },

    methods: {


        async onSubmit(values, { resetForm }) {
            console.log('here');
            // alert(JSON.stringify(values, null, 2));
            // console.log(values);

            // Submit values to API...


            axios.post('https://www.api.gleam.mx/email',values)
            .then( res => {
                console.log(res);
                if(res.data.ok)
                this.sended = true;            
                }).catch( error => {
                    console.log(error);
                    this.notsended = true;
                });

                resetForm();
        },
    },



};

</script>


<style >
.wrapper {
    /* border: 1px solid red; */
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    margin-left: -5%;
}

.sidebar-panel {
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 2;
    padding: 48px 20px 2rem 20px;
    /* width: 45rem; */
    width: 52.5%;
    background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0.035) 11.57%,
            rgba(255, 255, 255, 0.045) 72.62%
        ),
        url(../assets/Background-Form2x.png);

    /* mix-blend-mode: overlay; */
    /* border: 1px solid fuchsia; */
}

.contact-form {
    padding: 1.6% 2%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    overflow: hidden;
    /* border: 1px solid green; */
    width: 50%;
    /* filter: blur(3px) */
}

.sideblur-effect {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 47.6%;
    background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0.05) 11.57%,
        rgba(255, 255, 255, 0.1) 72.62%
    );
    /* background: rgba(128, 128, 128, 0.45); */
    /* border: 1px solid orange; */
}

.wrappering {
    /* border: 1px solid blue; */
}

.main-contact {
    padding-left: 2%;
}

.back-button {
    /* position:absolute; */
    /* margin-left:75%; */
    color: rgba(196, 196, 196, 0.5);
    cursor: pointer;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    /* border: 1px solid blue; */
    padding-right: 1%;
}
.contact-head {
    display: flex;
    justify-content: space-between;
}

.back-button-text:hover {
    text-decoration: underline;
}

.back-button:hover {
    color: #c4c4c4;
}
.backarrow-symbol {
    font-weight: 800;
    font-size: 1.65rem;
    margin-right: 0.1875rem;
}

.name {
    margin: 1.75% 0% 0% -0.95%;
}

.contact-form h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.875rem;
    line-height: 54px;
    color: #edf2f4;
    /* or 117% */
    /* border: 1px solid blue; */
}

.contact-form h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(237, 242, 244, 0.87);
    font-style: normal;
}

.contact-form p {
    padding-left: 0.6rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    letter-spacing: 0.15px;
    color: rgba(237, 242, 244, 0.87);
    margin-top: 3%;
}

.input-box {
    margin: 1.6%;
    background-color: rgba(237, 242, 244, 0.058);
    margin-bottom: 1.25rem;
    padding: 0.1875rem 0.7rem;
    font-size: 15px;
    border-radius: 5px;
    border: 0.1px solid rgba(191, 191, 191, 0.1);
    width: 100%;
    height: 46%;
    box-shadow: 0px 2.9px 4px rgba(0, 0, 0, 0.065);
    margin-top: 0.3%;
    /* padding-right: 6px; */
    /* margin-right: 21.85%; */
    margin-left: 0.05rem;
}
.input-box .input {
    background-color: transparent;
    color: rgba(237, 242, 244);
    border: none;
    outline: none;
    /* max-width: 18rem; */
    font-size: 14px;
    z-index: 1;
    width: 15.3vw;
    height: 4vh;
}

.error-message {
    color: #c4c4c4;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    position: absolute;
    margin-top: -0.88rem;
    margin-left: 0.5rem;
    letter-spacing: 0.01rem;
    /* border: 1px solid peru; */
}

.messagebox-error {
    margin-top: -0.65rem;
    margin-left: 3rem;
}

.success-message {
    margin-top: 1rem;
    margin-left: 3.6rem;
    color: #00EB00;
    font-size: .935rem;
    letter-spacing: 0.02rem;
    font-weight: 500;
    mix-blend-mode: overlay;
}
.suberror-message{
        margin-top: 1rem;
    margin-left: 3.6rem;
    color: #F7A7A7;
    font-size: .935rem;
    letter-spacing: 0.02rem;
    font-weight: 500;
    mix-blend-mode: overlay;
}
.name-fields {
    display: flex;
    align-items: flex-end;

    /* border: 1px solid purple; */
}

.firstrow-margin {
    margin-right: 8%;
}

.contact-fields {
    display: flex;
    align-items: flex-end;
    /* border: 1px solid purple; */
}

.message-field {
    /* border: 1px solid purple; */
}

.message-field .message-description {
    margin-top: 1.5%;
}

.text-area {
    margin-bottom: 2.5%;
    height: 16.5vh;
    width: 89.3%;
    /* border: 1px solid blue; */
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 1.3%;
}

textarea {
    resize: none;
}

.input-box .message {
    width: 100%;
    height: 100%;
    padding-right: 0.9%;
}

.submit-button {
    background-color: transparent;
    border: 0.5px solid #edf2f4;
    border-radius: 5px;
    color: #edf2f4;
    cursor: pointer;
    width: 22%;
    height: 6.2vh;
    font-weight: 300;
    font-family: "Helvetica Neue", "Helvetica";
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.8px;
}

.boxes-padding {
    padding-left: 4.2%;
}
.textbox-padding {
    padding-left: 1.5%;
}

::-webkit-scrollbar-thumb {
    background: #2b2929;
    border-radius: 4px;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    background: rgba(43, 41, 41, 0.4);
    border-radius: 0.25rem;
}

::-webkit-scrollbar:hover {
    background: rgba(43, 41, 41, 0.7);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar:hover:active {
    background: rgba(43, 41, 41, 0.5);
}

.input::placeholder {
    font-style: italic;
    font-weight: 400;
    font-size: 12.2px;
    line-height: 32px;
    color: rgba(237, 242, 244, 0.12);
    /* color:white;*/
    mix-blend-mode: overlay;
}

@media screen and (min-width: 1640px) {
    .contact-form {
        padding: 3.7% 2%;
    }

    .back-button {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
    }

    .backarrow-symbol {
        font-weight: 900;
        font-size: 1.95rem;
        margin-right: 0.1875rem;
    }


    .error-message {
    font-weight: 400;
    font-size: 12.2px;
    margin-top: -0.81rem;
    margin-left: 0.5rem;
    letter-spacing: 0.03rem;
    }


    .messagebox-error {
        margin-top: -0.65rem;
        margin-left: 3rem;
    }

    .success-message {
    margin-top: 1rem;
    margin-left: 3.6rem;
    color: #00EB00;
    font-size: .935rem;
    letter-spacing: 0.02rem;
    font-weight: 500;
    mix-blend-mode: overlay;
}

.suberror-message{
        margin-top: 1rem;
    margin-left: 3.6rem;
    color: #F7A7A7;
    font-size: .935rem;
    letter-spacing: 0.02rem;
    font-weight: 500;
    mix-blend-mode: overlay;
}
    .name {
        margin-top: 4.5%;
    }

    .text-area {
        height: 19.5vh;
    }

    .contact-form h1 {
        font-size: 3.5rem;
    }

    .contact-form h3 {
        font-size: 22px;
        letter-spacing: 0.4px;
    }

    .contact-form p {
        font-size: 16.5px;
        letter-spacing: 0.3px;
        margin-top: 5%;
        font-weight: 400;
        margin-bottom: 1%;
    }

    .submit-button {
        width: 19%;
        height: 5vh;
        font-size: 15px;
    }
    .input-box .message {
        font-size: 16px;
    }

    .text-area {
        width: 91%;
    }

    .text-area .message::placeholder {
        font-size: 13px;
    }

    /* .error-message { 
        font-size:12.6px
    } */
}
</style>